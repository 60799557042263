import { createApp } from 'vue';
import App from './App.vue';
const app = createApp(App);

import VueGtag from 'vue-gtag-next';
import { createI18n } from 'vue-i18n';
import VueSocialSharing from 'vue-social-sharing';
import vue3GoogleLogin from 'vue3-google-login';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import { fa } from 'vuetify/iconsets/fa';
import { aliases, mdi } from 'vuetify/lib/iconsets/mdi';
// import rollbar from './plugins/rollbar';
import router from './router';
import { createHead } from '@unhead/vue';
const head = createHead();
import store from './store';

import es from './locales/es';
import numberFormats from './locales/numberFormats';

const messages = {
    'es-ES': es,
};
const i18n = createI18n({
    locale: 'es-ES',
    messages,
    numberFormats,
});

app.use(router);
app.use(head);
app.use(store);
app.use(i18n);

const vuetify = createVuetify({
    icons: {
        defaultSet: 'mdi',
        aliases,
        sets: {
            mdi,
            fa,
        },
    },
    components,
    directives,
});
app.use(vuetify);
app.use(VueSocialSharing);
// app.use(rollbar, {
//     accessToken: import.meta.env.VITE_ROLLBAR_KEY,
//     captureUncaught: true,
//     captureUnhandledRejections: true,
//     payload: {
//         environment: import.meta.env.VITE_ENVIRONMENT,
//     },
// });
// app.config.errorHandler = (err, vm, info) => {
//     vm.$rollbar.error(err);
//     throw err;
// };
app.use(
    VueGtag,
    {
        property: {
            id: import.meta.env.VITE_GA_ID,
        },
        isEnabled: true,
    },
    router,
);
app.use(vue3GoogleLogin, {
    clientId: import.meta.env.VITE_GOOGLE_LOGIN_CLIENT_ID,
});

app.mount('#app');
window.$app = app;
