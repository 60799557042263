import router from '../../router';

async function redirectAfterRegisterOrRecoverPassword() {
    const urlParams = new URLSearchParams(window.location.search);
    const originParam = urlParams.get('origin');

    // Check if the URL includes the query origin=app
    if (originParam == 'app') {
        // Try opening the app
        window.location.href = 'alertacoches://';
    } else {
        router.push('/login');
    }
}

export { redirectAfterRegisterOrRecoverPassword };
