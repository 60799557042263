import { doLogin, doRedirectToGoogle } from '@/infrastructure/services/authService';

import store from '@/infrastructure/persistence/store/vuex';

async function login(query) {
    const vuexstore = store.repository;
    try {
        const response = await doLogin(query);

        if (response.status === 200) {
            response.data.user.token = response.data.token;
            vuexstore.dispatch('user/logIn', response.data.user);

            return true;
        }

        if (response.status >= 500) {
            vuexstore.dispatch(
                'feedbackInterface/setErrorResponse',
                'Error inesperado al iniciar sesión. Vuelve a intentarlo más tarde.',
            );
            return false;
        }

        vuexstore.dispatch('feedbackInterface/setErrorResponse', response.data.message);
        return false;
    } catch (error) {
        return false;
    }
}

function loginWithGoogle() {
    try {
        doRedirectToGoogle();
        return false;
    } catch (error) {
        return false;
    }
}

export { login, loginWithGoogle };
